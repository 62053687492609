import axios from 'axios'

export default {
  async getBalance ({ commit, rootGetters }) {
    const { id } = rootGetters['user/user']
    const url = `financial/getBalanceUser?user_id=${id}`
    const { data } = await axios.get(url)
    console.log('dataaaa', data)
    const points = data.data
    commit('SET_BALANCE', points)
  },
  async banks ({ commit, getters }) {
    const { banks } = getters
    if (banks.length) return
    const { data } = await axios.get('banks')
    commit('SET_BANKS', data)
    return true
  }
}
