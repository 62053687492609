import axios from 'axios'
import ViaCep from '../../../models/ViaCep'

export default {
  async fetch ({ commit, getters }, { province, city }) {
    const { cities } = getters

    if (city && cities.map(c => c.id).includes(city)) return
    const response = await axios.get('cities?province_id=' + province)
    commit('SET_CITIES', response.data)
    return true
  },

  async getViaCEP ({ dispatch }, { zipCode, province }) {
    const data = await ViaCep.fetchZip(zipCode)
    if (data.error) return false
    const { bairro, complemento, logradouro, ibge } = data

    const cityData = await dispatch('getCity', ibge)

    return {
      district: bairro,
      complement: complemento,
      street: logradouro,
      city_id: cityData.id,
      province_id: cityData.province_id
    }
  },

  async getCity ({ dispatch }, ibge) {
    const response = await axios.get('cities/ibge/' + ibge)
    await dispatch('fetch', { province: response.data.province_id, city: response.data.id })
    return response.data
  }
}
