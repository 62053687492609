import { get } from 'axios'

export default {
  async fetchItems ({ commit }, payload = '') {
    commit('SET_ITEMS', [])
    let url = ''
    if (typeof payload === 'object') {
      url = `items/closet?status=${payload.status}&start_date=${payload.startDate}&end_date=${payload.endDate}`
    } else {
      url = `items/closet?status=${payload}`
    }
    const { data } = await get(url)
    commit('SET_ITEMS', data)
  }
}
