import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/layouts/main.vue'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/index.vue')
      },
      {
        path: '/armazenar',
        name: 'store',
        component: () => import('@/views/store')
      },
      {
        path: '/armario',
        name: 'closet',
        component: () => import('@/views/closet')
      },
      {
        path: '/materiais-coletados',
        name: 'collected-materials',
        component: () => import('@/views/collected-materials')
      },
      {
        path: '/entidades-filantropicas',
        name: 'philanthropic-entities',
        component: () => import('@/views/philanthropic-entities')
      },

      {
        path: '/extratos',
        name: 'extracts',
        component: () => import('@/views/extracts')
      },
      {
        path: '/solicitar-coletas',
        name: 'request-collection',
        component: () => import('@/views/request-collection')
      },
      {
        path: '/coletas-agendadas',
        name: 'scheduled-collections',
        component: () => import('@/views/scheduled-collections')
      },
      {
        path: '/coletas-solicitadas',
        name: 'requested-collections',
        component: () => import('@/views/requested-collections')
      },
      {
        path: '/transferencias',
        name: 'transfers',
        component: () => import('@/views/transfers')
      },
      {
        path: '/ajudar-entidade',
        name: 'donations',
        component: () => import('@/views/donations')
      },
      {
        path: '/perfil',
        name: 'profile',
        component: () => import('@/views/profile')
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/layouts/auth.vue'),
    children: [
      {
        path: 'login',
        component: () => import('@/views/auth/login/index.vue')
      },
      {
        path: 'registro',
        component: () => import('@/views/auth/register/index.vue')
      },
      {
        path: 'request',
        component: () => import('@/views/auth/login/request_password.vue')
      },
      {
        path: 'reset',
        component: () => import('@/views/auth/login/reset_password.vue')
      }
    ]
  }
]

routes.push({
  path: '*',
  name: 'error-404',
  component: () => import('@/views/error-404')
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'current',
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('access_token')
  const { path } = to
  const authPaths = ['/auth/login', '/auth/registro', '/auth/request', '/auth/reset']
  if (!token && !authPaths.includes(path)) return next('/auth/login')
  try {
    const { exp } = JSON.parse(atob(token.split('.')[1]))
    if (exp * 1000 < new Date().getTime()) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('user')
      return next('/auth/login')
    }
    next()
  } catch (_) {
    return next()
  }
})

export default router
