import axios from 'axios'

export default {
  async login ({ commit }, credentials) {
    try {
      const url = 'auth/login'
      const { data } = await axios.post(url, credentials)
      const user = data
      axios.defaults.headers.Authorization = 'Bearer ' + data.access_token
      commit('SET_USER', user)
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('user', JSON.stringify(user))
      return true
    } catch (_) {
      return false
    }
  },

  async logout ({ commit }, credentials) {
    try {
      const url = 'user-remove'
      await axios.post(url, credentials)
      return true
    } catch (_) {
      return false
    } finally {
      commit('SET_USER', {})
      localStorage.setItem('access_token', '')
      window.location.href = '/'
    }
  },

  getFromStorage ({ commit }) {
    const localUser = localStorage.getItem('user')
    const localAccessToken = localStorage.getItem('access_token')

    if (!localUser || !localAccessToken) return false
    const user = JSON.parse(localUser)
    axios.defaults.headers.Authorization = `Bearer ${localAccessToken}`
    commit('SET_USER', user)
    return true
  }
}
